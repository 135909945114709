<template>
  <div>
    <section class="mb-4 flex gap-2 md:gap-4">
      <div class="w-2/5 md:w-2/6">
        <SharedPortraitImage v-if="portraitImageData" :data="portraitImageData" />
      </div>
      <div class="w-3/5 md:w-4/6">
        <SharedDocfinderRating
          v-if="hasDocFinderRating"
          :rating="healthProfessional.docfinderRating"
          layout="card"
          class="mb-1 md:mb-2 lg:mb-4" />
        <h1 class="mb-1 font-medium leading-tight text-gray-900 md:text-2xl md:font-normal lg:text-3xl">
          {{ healthProfessional?.fullName }}
        </h1>
        <p class="text-sm leading-tight text-gray-900 md:mb-1 md:text-base">
          {{ healthProfessional?.workingTitle }}
        </p>
        <div v-if="healthProfessional.languages" class="mt-2 md:mt-4">
          <p class="mb-2 text-xs text-gray-700">Sprachen</p>
          <SharedLanguageList :languages="healthProfessional.languages?.data" :show-language-initials="false" />
        </div>
      </div>
    </section>

    <section>
      <div class="sticky top-24 flex justify-end" :class="{ 'z-10': moreInfoDropdown }">
        <button
          type="button"
          class="min-w-[120px] rounded-heavy border border-gray-900 px-2 py-1 text-xs md:hidden"
          data-track-profile="true"
          :class="{ 'bg-white': moreInfoDropdown }"
          @click="toggleMoreInfoDropdown">
          {{ accordionText }}
        </button>
      </div>

      <div class="flex flex-col gap-4 pt-5" :class="moreInfoDropdown ? 'flex' : 'hidden md:flex'">
        <div @click.capture.prevent="handleLinkClick($event)">
          <HealthProfessionalDetails :details="accordionData.details" :testimonials="accordionData.testimonials" />
        </div>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import type { HealthProfessional } from "~~/types/strapi/api/health-professional/content-types/health-professional/health-professional";

type HealthProfessionalDeep = HealthProfessional["attributes"];

const props = defineProps<{
  healthProfessional: HealthProfessionalDeep;
}>();

const accordionData = computed(() => {
  return {
    details: props.healthProfessional.details,
    testimonials: props.healthProfessional.testimonials?.data,
  };
});

const portraitImageData = computed(() => {
  return props.healthProfessional.avatar?.data.attributes;
});

const hasDocFinderRating = computed(() => props.healthProfessional.docfinderRating !== 0);

const moreInfoDropdown = ref(false);
const toggleMoreInfoDropdown = () => {
  moreInfoDropdown.value = !moreInfoDropdown.value;
};
const accordionText = computed(() => {
  return moreInfoDropdown.value ? "Weniger Info" : "Profil ansehen";
});
</script>

<style scoped></style>
